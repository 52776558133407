import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c('div',{staticClass:"d-flex"},[_c(VBtn,{attrs:{"depressed":"","tile":"","height":"40"},on:{"click":function () { return _vm.$router.replace({ name: 'store' }); }}},[_c(VIcon,[_vm._v(" mdi-arrow-left ")])],1),(_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex"},_vm._l(([1,2, 3]),function(i){return _c(VSkeletonLoader,_vm._b({key:i,attrs:{"type":"list-item","tile":"","max-height":"40","width":"100"}},'v-skeleton-loader',_vm.attrs,false))}),1)]):_c('bookmark-tabs',{staticStyle:{"width":"100%"},attrs:{"items":_vm.getCategories,"id-prefix":"cat-","container":_vm.$refs.viewFrame},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c('div',{ref:"viewFrame",staticClass:"overflow-y-auto px-5 pb-5",style:(_vm.getViewHeightStyle(116))},[(_vm.loading)?_c(VRow,{staticClass:"pt-3"},_vm._l(([1,2,3,4,5]),function(i){return _c(VCol,{key:i,attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"6"}},[_c(VSkeletonLoader,_vm._b({staticClass:"shadow",attrs:{"type":"image, list-item-avatar, divider, actions"}},'v-skeleton-loader',_vm.attrs,false))],1)}),1):_vm._l((_vm.getCategories),function(cat,i){return _c('div',{key:i},[_c('section-header',{attrs:{"title":cat.name,"bookmark":'cat-' + cat.id,"icon":"mdi-shape-outline"}}),_c(VRow,{directives:[{def: Touch,name:"touch",rawName:"v-touch",value:({
          left: function () { return _vm.swipe(cat.id, 'left'); },
          right: function () { return _vm.swipe(cat.id, 'right'); },
        }),expression:"{\n          left: () => swipe(cat.id, 'left'),\n          right: () => swipe(cat.id, 'right'),\n        }"}],staticClass:"mb-5"},_vm._l((cat.products),function(item){return _c(VCol,{key:item.id,staticClass:"pr-3",attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"6"}},[_c('product-card',{attrs:{"item":item,"disabled":item.stock <= 0,"warning":_vm.getCartItem(item.id) &&
              item.stock < _vm.getCartItem(item.id).quantity,"favored":!!_vm.favored(item.id),"show-rating":""},on:{"click:card":function () { return _vm.$router.push({ name: 'product', params: { id: item.id } }); }}})],1)}),1)],1)})],2),_c('locked-cart')],1)}
var staticRenderFns = []

export { render, staticRenderFns }