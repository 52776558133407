<template>
  <div v-if="notEmptyArray(items)" style="z-index: 9">
    <v-tabs v-model="getActiveTab" show-arrows height="40">
      <!-- dark -->
      <!-- background-color="grey lighten-5" -->
      <v-tabs-slider color="warning"></v-tabs-slider>
      <v-tab
        v-for="item in items"
        :key="item.id"
        @click="goTo(item.id)"
        class="primary--text"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "BookmarkTabs",
  model: {
    prop: "tab",
    event: "input",
  },
  props: {
    tab: String,
    container: HTMLDivElement,
    items: Array,
    idPrefix: {
      type: String,
      default() {
        return "item-";
      },
    },
  },
  computed: {
    getActiveTab: {
      get() {
        return this.idPrefix + this.tab;
      },
      set(v) {
        this.$emit("input", this.idPrefix + v);
      },
    },
  },
  methods: {
    goTo(id) {
      this.$vuetify.goTo("#" + this.idPrefix + id, {
        offset: -40,
        container: this.container,
      });
    },
  },
};
</script>
