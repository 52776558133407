<template>
  <view-container breadcrumbs scroll-fab>
    <div class="d-flex">
      <v-btn
        @click="() => $router.replace({ name: 'store' })"
        depressed
        tile
        height="40"><v-icon> mdi-arrow-left </v-icon>
      </v-btn>

      <v-row v-if="loading" no-gutters>
        <div class="d-flex">
          <v-skeleton-loader
            v-for="i in [1,2, 3]"
            :key="i"
            v-bind="attrs"
            type="list-item"
            tile
            max-height="40"
            width="100"
          ></v-skeleton-loader>
        </div>
      </v-row>

      <bookmark-tabs
        v-else
        v-model="tab"
        :items="getCategories"
        id-prefix="cat-"
        :container="$refs.viewFrame"
        style="width: 100%"
      />
    </div>
    <div
      ref="viewFrame"
      class="overflow-y-auto px-5 pb-5"
      :style="getViewHeightStyle(116)"
    >
      <v-row v-if="loading" class="pt-3">
        <v-col xl="2" lg="3" md="4" sm="6" cols="6" v-for="i in [1,2,3,4,5]" :key="i">
          <v-skeleton-loader
            v-bind="attrs"
            type="image, list-item-avatar, divider, actions"
            class="shadow"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <div v-else v-for="(cat, i) in getCategories" :key="i">
        <section-header
          :title="cat.name"
          :bookmark="'cat-' + cat.id"
          icon="mdi-shape-outline"
        />

        <v-row
          class="mb-5"
          v-touch="{
            left: () => swipe(cat.id, 'left'),
            right: () => swipe(cat.id, 'right'),
          }"
        >
          <v-col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            cols="6"
            v-for="item in cat.products"
            :key="item.id"
            class="pr-3"
          >
            <product-card
              :item="item"
              :disabled="item.stock <= 0"
              :warning="
                getCartItem(item.id) &&
                item.stock < getCartItem(item.id).quantity
              "
              :favored="!!favored(item.id)"
              show-rating
              @click:card="
                () => $router.push({ name: 'product', params: { id: item.id } })
              "
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <locked-cart />
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/views/stores/ProductCard";
import BookmarkTabs from "@/common/components/BookmarkTabs";
import SectionHeader from "@/common/components/SectionHeader";
import ViewContainer from "@/common/layouts/ViewContainer";
import LockedCart from "@/views/orders/LockedCart.vue";

export default {
  name: "OpenSection",
  components: {
    ProductCard,
    BookmarkTabs,
    SectionHeader,
    LockedCart,
    ViewContainer,
  },
  props: {
    sectionId: Number,
    name: String,
    image: String,
  },
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    getCategories: function (n) {
      if (this.notEmptyArray(n)) {
        this.tab = "cat-" + n[0].id;
      }
    },
  },
  mounted() {
    this.loadCategoriesByParentId(this.getIdParam).then(() => this.loading = false);

    // no need for reactive favorates in this case because the
    // product item would be gone along with its favorite icon
    // and create/update products won't affect favorites either
    this.loadFavoritesByParams({ storeId: this.getStoreIdParam });

    this.setReactivityParams({
      resource: "CATEGORIES",
      scope: "store/section",
      key: `${this.getStoreIdParam}/${this.getIdParam}`,
    });
    this.$sub("reactivity/CATEGORIES", (payload) => {
      this.loading = true;
      this.reloadCategoriesByParams({
        storeId: this.getStoreIdParam,
        sectionIds: [this.getIdParam],
      }).then(() => (this.loading = false));
      console.log("reactivity/CATEGORIES:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("categories", ["getCategories"]),
    ...mapGetters("favorites", ["getFavoriteById", "getFavorites"]),
    ...mapGetters("cart", ["getCartItem"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getIdParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions("categories", [
      "loadCategoriesByParentId",
      "loadCategoriesByParams",
      "reloadCategoriesByParams",
    ]),
    ...mapActions("favorites", ["loadFavoritesByParams"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),

    favored(id) {
      return !!this.getFavoriteById(id);
    },
    swipe(id, dir) {
      const cats = this.getCategories;
      const current = cats.find((f) => f.id === id);
      const index = cats.indexOf(current);
      if (dir === "right" && index > 0) {
        this.goTo(cats[index - 1].id);
      } else if (dir === "left" && index < cats.length - 1) {
        this.goTo(cats[index + 1].id);
      }
    },
    goTo(id) {
      this.$vuetify.goTo("#cat-" + id, {
        offset: -40,
        container: this.$refs.viewFrame,
      });
      this.tab = "cat-" + id;
    },
  },
};
</script>
